import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Layout from "../core/Layout";
import { RouteComponentProps } from "react-router";
import { Link } from "gatsby";
import SectionHeader from "./SectionHeader";
import NewsletterRow from "../newsletter/NewsletterRow";
import FeaturedJobsCard from "../jobs/FeaturedJobsCard";
import ArticleCardV2 from "../../tailwindComponents/ArticleCardV2";
import { StaticImage } from "gatsby-plugin-image";
import Tweet from "../../tailwindComponents/Tweet";
import ComponentPreviewCardV2 from "../../tailwindComponents/ComponentPreviewCardV2";
import WallOfLove from "../../tailwindComponents/WallOfLove";
import { shuffleArray } from "../../utils/ArrayUtils";
import CompanyRow from "../../tailwindComponents/CompanyRow";

interface LandingScreenProps extends RouteComponentProps {
  pageContext: {
    wallOfLove: any[];
    catalog: any[];
    articles: any[];
    newsletters: any[];
    resources: any[];
    featuredJobs: any[];
    snippets: any[];
    totalUsers: Number;
  };
}

export default function LandingScreen(props: LandingScreenProps) {
  const classes = useStyles();
  const [signupSuccessful, setSignupSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");

  const [randomProjects, setRandomProjects] = useState<any[]>([]);
  const [randomSnippets, setRandomSnippets] = useState<any[]>([]);
  useEffect(() => {
    setRandomProjects(shuffleArray(props.pageContext.catalog).slice(0, 4));
  }, []);
  useEffect(() => {
    setRandomSnippets(shuffleArray(props.pageContext.snippets).slice(0, 4));
  }, []);

  const totalUsers = `${props.pageContext.totalUsers
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();
    if (window && !window.location.href.includes("localhost")) {
      window.gtag(`event`, `click`, {
        event_category: `engagement`,
        event_label: "landing_screen_subscribe",
      });
    }
    try {
      const currentUrl = window.location.href;
      if (email.length && validEmail(email)) {
        setLoading(true);
        const formData = {
          email: email,
          referringSite: currentUrl,
        };
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.result === "success") {
          setTimeout(() => {
            setSignupSuccessful(true);
            setLoading(false);
          }, 1000);
        } else {
          setSignupSuccessful(false);
          setLoading(false);
        }
      }
    } catch (error) {
      setSignupSuccessful(false);
      setLoading(false);
    }
  };

  function validEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length === 0 || re.test(String(email).toLowerCase());
  }

  return (
    <>
      <Layout
        maxWidth="xl"
        pageTitle="JetpackCompose.app | The #1 destination for all things Jetpack Compose"
        pageDescription="Find in-depth articles, snippets, newsletters, tools &amp; resources about Jetpack Compose. Jetpack Compose is a modern, declarative-first UI toolkit for building native Android apps. Use significantly less code and build beautiful, pixel-perfect UIs in minutes."
        pageSlug="/"
        pageImageUrl="/landing.png"
        seoImageUrl="/landing.png"
      >
        {/* Hero section */}
        <Container maxWidth="lg">
          <Grid
            lg={12}
            xs={12}
            md={12}
            container
            className={classes.heroContainer}
          >
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
              className={classes.heroTitleContainer}
            >
              <div>
                <Box width={1}>
                  <h1 className={classes.heroTitle}>
                    The #1 destination for all things{" "}
                    <span className={classes.composeSpan}>Jetpack Compose</span>
                    {/* If you care about Jetpack Compose, we should be best friends! */}
                    {/* The #1 destination for learning how to build mobile apps with
                Jetpack Compose */}
                  </h1>
                </Box>
                <Box width={1}>
                  <div className={classes.heroSubtitle}>
                    Join{" "}
                    <Link to="/impact">
                      <span className={classes.totalUsersSpan}>
                        {totalUsers}
                      </span>
                    </Link>{" "}
                    Android developers and get in-depth articles, tools {"&"}{" "}
                    resources about Jetpack Compose - the best way to build an
                    Android app 🤖📱
                  </div>
                </Box>
                <Box width={1}>
                  <Grid container lg={12} xs={12} md={12}>
                    <Grid item lg={7} xs={12} md={7}>
                      <TextField
                        id="landing-hero-email-address"
                        label="Type your email"
                        variant="outlined"
                        className={classes.emailTextField}
                        error={!validEmail(email)}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        autoFocus
                        // InputProps={{
                        //   className: classes.inputProps,
                        // }}
                      />
                    </Grid>
                    <Grid item lg={5} md={5} xs={12}>
                      <Button
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : signupSuccessful ? (
                          "Subscribed!"
                        ) : (
                          "Subscribe"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box width={1}>
                  <CompanyRow />
                </Box>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className={classes.heroImage}>
              <StaticImage
                src="../../images/JetpackCompose_Landing_Hero.png"
                className="heroImageResource"
                alt="JetpackCompose.app hero image"
                placeholder="blurred"
                transformOptions={{ fit: "inside" }}
                width={700}
              />
            </Grid>
          </Grid>
        </Container>
        {/* Hero section end*/}

        {props.pageContext.featuredJobs.length ? (
          <Container maxWidth="lg" className={classes.sectionContainer}>
            <SectionHeader
              title="Featured Jetpack Compose &amp; Android Jobs"
              viewMoreTitle="See all jobs"
              viewMoreOutboundUrl="https://jetpackcompose.pallet.com"
            />
            <Grid
              container
              lg={12}
              spacing={3}
              className={classes.articleContainer}
            >
              {props.pageContext.featuredJobs.map((job) => {
                return (
                  <Grid item lg={3} xs={12} md={6} sm={6}>
                    <FeaturedJobsCard
                      companyName={job.companyName}
                      companyLogoUrl={job.companyLogoUrl}
                      jobTitle={job.jobTitle}
                      jobDescription={job.jobDescription}
                      location={job.location}
                      jobPostUrl={job.jobPostUrl}
                      valueBadges={job.valueBadges}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        ) : (
          <></>
        )}

        {/* Wall of Love section */}
        <Grid lg={12} container className={classes.wallOfLove} spacing={0}>
          <Grid item lg={12}>
            <div className={classes.wallOfLoveTitle}>
              Don't take our word for it, here's what our users have to say ♥️
            </div>
          </Grid>
          <Container maxWidth="lg">
            <WallOfLove wallOfLove={props.pageContext.wallOfLove} />
          </Container>
        </Grid>
        {/* Wall of love section end */}

        {/* Newsletters section starts*/}
        <Container maxWidth="lg" className={classes.sectionContainer}>
          <SectionHeader
            title="Latest Newsletter Issues"
            viewMoreTitle="Browse all"
            viewMoreSlug="/newsletter"
          />
          <Grid
            container
            lg={12}
            spacing={3}
            className={classes.articleContainer}
          >
            {props.pageContext.newsletters.map((newsletter) => {
              return (
                <Grid item lg={4} xs={12} md={6}>
                  <Link to={newsletter.frontmatter.slug} partiallyActive={true}>
                    <ArticleCardV2
                      title={newsletter.frontmatter.title}
                      description={newsletter.frontmatter.description}
                      dynamicImage={newsletter.frontmatter.heroImageUrl}
                      date={newsletter.frontmatter.date}
                      tags={newsletter.frontmatter.tags}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* Newsletters section ends*/}

        {/* Articles section starts*/}
        <Container maxWidth="lg" className={classes.sectionContainer}>
          <SectionHeader
            title="Latest Articles"
            viewMoreTitle="Browse all"
            viewMoreSlug="/articles"
          />
          <Grid
            container
            lg={12}
            spacing={3}
            className={classes.articleContainer}
          >
            {props.pageContext.articles.map((article) => {
              return (
                <Grid item lg={4} xs={12} md={6}>
                  <Link to={article.frontmatter.slug} partiallyActive={true}>
                    <ArticleCardV2
                      title={article.frontmatter.title}
                      description={article.frontmatter.description}
                      dynamicImage={article.frontmatter.heroImageUrl}
                      date={article.frontmatter.date}
                      tags={article.frontmatter.tags}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* Articles section ends*/}

        {/* Resources section starts*/}
        <Container maxWidth="lg" className={classes.sectionContainer}>
          <SectionHeader title="Free tools &amp; resources" />
          <Grid
            container
            lg={12}
            spacing={3}
            className={classes.articleContainer}
          >
            {props.pageContext.resources.map((resource) => {
              return (
                <Grid item lg={6} xs={12} md={6}>
                  <Link
                    to={resource.resourceSlug}
                    className={classes.blogLink}
                    partiallyActive={true}
                  >
                    <ArticleCardV2
                      title={resource.name}
                      description={resource.description}
                      dynamicImage={resource.image}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* Resources section ends*/}

        {/* Community Projects section starts*/}
        <Container maxWidth="lg" className={classes.sectionContainer}>
          <SectionHeader
            title="Community Projects"
            viewMoreTitle="Browse all"
            viewMoreSlug="/compose-catalog"
          />
          <Grid
            container
            lg={12}
            spacing={3}
            className={classes.articleContainer}
          >
            {randomProjects.map((catalogItem) => {
              return (
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <ComponentPreviewCardV2
                    title={catalogItem.title}
                    description={catalogItem.description}
                    image={catalogItem.image}
                    video={catalogItem.video}
                    resourceLink={catalogItem.url}
                    categories={catalogItem.categories}
                    key={catalogItem.url}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* Community section ends*/}

        {/* Community Code Snippets section starts*/}
        <Container maxWidth="lg" className={classes.sectionContainer}>
          <SectionHeader
            title="Community Code Snippets"
            viewMoreTitle="Browse all"
            viewMoreSlug="/snippets"
          />
          <Grid
            container
            lg={12}
            spacing={3}
            className={classes.articleContainer}
          >
            {randomSnippets.map((snippet) => {
              return (
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Link
                    to={snippet.slug}
                    className={classes.blogLink}
                    partiallyActive={true}
                  >
                    <ArticleCardV2
                      title={snippet.name}
                      description={snippet.description}
                      tags={snippet.tags}
                      key={snippet.slug}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* Community section ends*/}

        <Container maxWidth="lg" className={classes.sectionContainer}>
          <div className={classes.newsletterContainer}>
            <NewsletterRow />
          </div>
        </Container>
      </Layout>
    </>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  heroContainer: {
    marginTop: 64,
    marginBottom: 64,
  },
  heroTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heroTitle: {
    color: "#222222",
    fontSize: "3.5rem",
    fontWeight: 600,
    fontFamily: "Inter",
    lineHeight: 1.2,
    marginTop: 0,
    marginBottom: 0,
  },
  heroSubtitle: {
    color: "#374151",
    fontSize: 24,
    fontFamily: "Inter",
    fontWeight: 400,
    marginTop: 32,
    marginBottom: 32,
    "& a": {
      color: "#374151",
      "&:hover": {
        color: "#4636f7",
      },
    },
  },
  heroImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: -1,
  },
  wallOfLove: {
    // background: "#3a10e5",
    // background: "#F7F3EA",
    // background: "#ffd300",
    paddingBottom: 64,
  },
  wallOfLoveTitle: {
    color: "#222222",
    fontSize: 32,
    fontWeight: 600,
    fontFamily: "Nunito Sans",
    paddingTop: 64,
    paddingBottom: 8,
    textAlign: "center",
    paddingLeft: 12,
    paddingRight: 12,
  },
  sectionContainer: {
    marginBottom: 64,
  },
  articleContainer: {},
  blogLink: {
    width: "100%",
    display: "inline-block",
    textDecoration: "none",
  },
  resourceImage: {
    borderRadius: 12,
    objectFit: "cover",
    backgroundSize: "contain",
    width: "100%",
    border: "1px solid rgba(0,0,0,.12)",
  },
  heroImageResource: {
    objectFit: "cover",
    backgroundSize: "contain",
    width: "100%",
  },
  composeSpan: {
    // https://cssgradient.io/blog/css-gradient-text/
    // color: "rgba(252, 37, 103, 1)",
    backgroundImage:
      "radial-gradient( circle farthest-corner at 10.2% 55.8%,  rgba(252,37,103,1) 0%, rgba(250,38,151,1) 25.2%, rgba(186,8,181,1) 90.1% )",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozTextFillColor: "transparent",
    fontSize: "4.5rem",
    // https://stackoverflow.com/a/65330979/1762592 Fixes the issue where the gradient text wasn't rendered properly on Safari/iPhones
    WebkitBoxDecorationBreak: "clone",
  },
  totalUsersSpan: {
    color: "#4636f7",
    fontWeight: 500,
  },
  emailTextField: {
    width: "100%",
  },
  submitButton: {
    backgroundColor: "#4636f7 !important",
    color: "#FFFFFF",
    padding: 12,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      marginBottom: 16,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 16,
    },
    "&:hover": {
      backgroundColor: "#3428b5",
    },
  },
  newsletterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
